import Papa from 'papaparse';
import data from '../data/games3.csv';

export default class DataService {
	loadData(setData, setInitialData) {
		fetch(data)
			.then((response) => response.text())
			.then((text) => {
				Papa.parse(text, {
					header: true,
					dynamicTyping: true,
					transformHeader: (header) => {
						if (header === 'Hero Image [Vertical Orientation]') {
							return 'cardImg';
						} else if (header === 'Hero Image [Horizontal Orientation]') {
							return 'cardHorizontalImg';
						} else {
							return header.replaceAll(' ', '');
						}
					},
					complete: (result) => {
						result.data = this.handleDefaultSorting(result.data);
						setData(result.data);
						setInitialData(result.data);
					}
				});
			})
			.catch((error) => console.log(error));
	}

	handleDefaultSorting(data) {
		let sortedData = data.filter((game) => game.ReleaseDate && game.GameID);
		sortedData = sortedData.filter((game) => {
			return !game.Slug.includes('branded');
		});
		sortedData = sortedData.filter((game) => game.GameDemoLink && game.GameDemoLink.includes('https://bgaming-network.com/'));
		sortedData.map((game) => {
			game.GameDemoLink = game.GameDemoLink.replace('/EUR', '/FUN');
			return game;
		});
		sortedData = this.filterInactiveGames(sortedData);
		sortedData = sortedData.sort((a, b) => {
			return new Date(b.ReleaseDate) - new Date(a.ReleaseDate);
		});
		return sortedData;
	}

	filterInactiveGames(data) {
		const INACTIVE = [
			'TreyPoker',
			'TexasHoldem',
			'Pontoon',
			'OasisPoker',
			'JacksOrBetter',
			'HiLoSwitch',
			'DoubleExposure',
			'CasinoHoldem',
			'CaribbeanPoker',
			'Baccarat',
			'SicBoMacau',
			'SicBo'
		];
		return data.filter((game) => !INACTIVE.includes(game.GameID));
	}
}
