import styles from './GameFeatureDescription.module.css';
import dropsBackground from '../../assets/drop_description_background.png';
import challengesBackground from '../../assets/challenges_description_background.png';
import { FormattedMessage } from 'react-intl';
import { SELECTED_FEATURE } from '../../hooks/useGlobalState';
import viewBack from '../../assets/viewBack.svg';
import React from 'react';

const features = {
	[SELECTED_FEATURE.DROP]: {
		imagePath: dropsBackground,
		firstBlock: { title: 'dropsTitle', subtitle: 'dropsSubtitle' },
		secondBlock: { title: 'dropsLoyaltyTitle', subtitle: 'dropsLoyaltySubtitle' },
		thirdBlock: { title: 'dropsToolTitle', subtitle: 'dropsToolSubtitle' },
		fourthBlock: { title: 'dropsMotivatesTitle', subtitle: 'dropsMotivatesSubtitle' },
		fifthBlock: { title: 'dropsExperienceTitle', subtitle: 'dropsExperienceSubtitle' }
	},
	[SELECTED_FEATURE.CHALLENGES]: {
		imagePath: challengesBackground,
		firstBlock: { title: 'challengesTitle', subtitle: 'challengesSubtitle' },
		secondBlock: { title: 'challengesMotivationTitle', subtitle: 'challengesMotivationSubtitle' },
		thirdBlock: { title: 'challengesToolTitle', subtitle: 'challengesToolSubtitle' },
		fourthBlock: { title: 'Higher Engagement Rates', subtitle: 'challengesRatesSubtitle' },
		fifthBlock: { title: 'challengesExperienceTitle', subtitle: 'challengesExperienceSubtitle' }
	}
};

function GameFeatureDescription({ selectedFeature, clearGameSrc }) {
	//todo!:  useGlobalState => GAME_TYPE[...]
	const feature = features[selectedFeature];

	if (!feature) return null;

	const { imagePath, firstBlock, secondBlock, thirdBlock, fourthBlock, fifthBlock } = feature;

	return (
		<div className={styles.info_container}>
			<div className={styles.button_view_back} onClick={clearGameSrc}>
				<img src={viewBack} alt="" />
			</div>
			<div className={styles.wrapper}>
				<div className={styles.container}>
					<div className={styles.wrapper_text}>
						<div className={styles.main_text}>
							<div className={styles.text_header}>
								<FormattedMessage id={firstBlock.title} />
							</div>
							<div className={styles.subtitle}>
								<FormattedMessage id={firstBlock.subtitle} />
							</div>
						</div>
						<div className={styles.row}>
							<div className={styles.columns}>
								<div className={styles.text_wrapper}>
									<div className={styles.title}>
										<FormattedMessage id={secondBlock.title} />
									</div>
									<div className={styles.subtitle}>
										<FormattedMessage id={secondBlock.subtitle} />
									</div>
								</div>
								<div className={styles.text_wrapper}>
									<div className={styles.title}>
										<FormattedMessage id={thirdBlock.title} />
									</div>
									<div className={styles.subtitle}>
										<FormattedMessage id={thirdBlock.subtitle} />
									</div>
								</div>
							</div>
							<div className={styles.columns}>
								<div className={styles.text_wrapper}>
									<div className={styles.title}>
										<FormattedMessage id={fourthBlock.title} />
									</div>
									<div className={styles.subtitle}>
										<FormattedMessage id={fourthBlock.subtitle} />{' '}
									</div>
								</div>
								<div className={styles.text_wrapper}>
									<div className={styles.title}>
										<FormattedMessage id={fifthBlock.title} />
									</div>
									<div className={styles.subtitle}>
										<FormattedMessage id={fifthBlock.subtitle} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default GameFeatureDescription;
