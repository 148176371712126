import styles from './StickyMenu.module.css';
import React, { useState, useEffect } from 'react';
import { useGlobalState, ACTION_TYPE } from '../../hooks/useGlobalState';

function StickyMenu() {
	const { state, dispatch } = useGlobalState();
	const [currentLanguage, setCurrentLanguage] = useState(state.language);
	const languages = ['en', 'es'];

	const switchLanguage = (language) => {
		dispatch({ type: ACTION_TYPE.SET_LANGUAGE, payload: language });
	};

	const handleLanguageSwitcher = (language) => {
		if (language !== currentLanguage) {
			setCurrentLanguage(language);
			switchLanguage(language);
		}
	};

	return (
		<div className={styles.modal}>
			<div className={styles.content_wrapper}>
				{languages.map((lang) => {
					return (
						<div key={lang} onClick={() => handleLanguageSwitcher(lang)}>
							<div
								className={
									lang === currentLanguage
										? `${styles.content} ${styles.content_active}`
										: `${styles.content}`
								}
							>
								<span className={styles.icon_wrapper}>
									<span className={styles.icon_text}>{lang}</span>
								</span>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default StickyMenu;
