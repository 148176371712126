import styles from './SimilarGames.module.css';
import GameCard from '../GameCard';
import { FormattedMessage } from 'react-intl';
import { useGlobalState, ACTION_TYPE, SELECTED_FEATURE } from '../../hooks/useGlobalState';

function SimilarGames({ similarGames, isVisible, handlePlayLink }) {
	const { dispatch } = useGlobalState();

	if (!similarGames || !similarGames.length) return;

	const updateGameSrc = (playLink) => dispatch({ type: ACTION_TYPE.SET_GAME_SRC, payload: playLink });
	const updateGameId = (GameID) => dispatch({ type: ACTION_TYPE.SET_GAME_ID, payload: GameID });
	const updateFeatureGame = () => dispatch({ type: ACTION_TYPE.SELECT_FEATURE, payload: SELECTED_FEATURE.BASE_GAME });

	const handleClick = () => {
		const index = Math.floor(Math.random() * similarGames.length);
		const { GameID } = similarGames[index];
		const playLink = handlePlayLink(GameID);

		updateGameSrc(playLink);
		updateGameId(GameID);
		updateFeatureGame();
	};

	if (!isVisible) return null;
	const isCardsMorelWidth = similarGames.length > 7;

	const ButtonRandom = () => (
		<button className={styles.button} onClick={handleClick}>
			<span>
				<FormattedMessage id="randomGame" />
			</span>
			<span className={styles.icon}></span>
		</button>
	);
	return (
		<div className={`${styles.wrapper} ${isVisible ? '' : styles.hidden}`}>
			<div className={styles.container}>
				<div className={styles.header}>
					<div className={styles.title}>
						<FormattedMessage id="similarGamesTitle" />
					</div>
					{/*{isCardsMorelWidth && <ButtonRandom />}*/}
				</div>
				<div className={`${styles.list} ${isCardsMorelWidth ? styles.scrollable : ''}`}>
					{similarGames.map(({ GameID, cardImg }, index) => (
						<div className={styles.card} key={index}>
							<GameCard playLink={handlePlayLink(GameID)} gameId={GameID} cardImg={cardImg} />
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default SimilarGames;
